import React from 'react';
import { withPrefix } from 'gatsby';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import link from '../theme/styles/link';
import footerChevron from '../theme/styles/footerChevron';
import withRoot from '../withRoot';

const styles = () => ({
  ...container,
  ...section,
  ...link,
  ...footerChevron,
});

const Accessibility = ({ classes, location }) => (
  <Layout location={location} title="Accessibility">
    <div className={classNames(classes.container, classes.section)}>
      <Typography component="h1" variant="h1">
        Accessibility Statement
      </Typography>
      <Typography component="p" variant="subtitle1">
        TWO MEN AND A TRUCK®/International Inc., is committed to ensuring our
        website functions for all site visitors and meets the requirements set
        forth by the{' '}
        <a href="https://www.w3.org/TR/WCAG20/" className={classes.link}>WCAG 2.1 AA</a>{' '}
        Web Content Accessibility Guidelines for those with disabilities.
      </Typography>

      <Typography component="p" variant="subtitle1">
        We have conducted third-party audits, development and testing to ensure
        content is readily accessible and compatible through screen readers,
        provides full keyboard functionality, and allows our web content to
        communicate effectively without dependency on colors. As part of an
        ongoing accessibility initiative, we are using the Siteimprove
        Intelligence Platform&reg; to help identify and prioritize accessibility
        issues on our website.
      </Typography>

      <Typography component="p" variant="subtitle1">
        If you are experiencing any accessibility issues with
        twomenandatruck.com, we would love to hear from you! Please email us at{' '}
        <a href="mailto:accessibility@twomen.com" className={classes.link}>accessibility@twomen.com</a>{' '}
        and include specific information such as the webpage or navigation area
        where you encountered the accessibility issue, and we will make all
        reasonable efforts to further improve accessibility.
      </Typography>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(Accessibility));
